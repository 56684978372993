<template>
  <div>
    <v-card
      v-if="!registerSuccess"
      class="emptyLayoutBg px-sm-20 rounded-0 py-20"
      flat
      :max-width="checkMd ? '100%' : checkSm ? 550 : 280"
    >
      <div
        class="text-uppercase pb-16 pb-sm-33 mr-sm-n2 loginTitle--text"
        :class="checkSm ? 'font-large-login text-center pl-166px' : 'font-row-title'"
      >register</div>
    
      <v-form v-model="validForm" ref="form">
        <v-row no-gutters>
          <v-col cols="12" xl="6">
            <v-row no-gutters>
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">First name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regFirstName"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData['first_name']"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regFirstName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Last name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regLastName"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData['last_name']"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regLastName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Email Address</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regEmail"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.email"
                  class="login-form-input"
                  :rules="emailRules"
                >
                  <template #message="{ key, message }">
                    <portal to="regEmail" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Password</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regPassword"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.password"
                  class="login-form-input"
                  type="password"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regPassword" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Password confirm</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regPasswordConfirm"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData['password_confirmation']"
                  class="login-form-input"
                  type="password"
                  :rules="passwordConfirmRules"
                >
                  <template #message="{ key, message }">
                    <portal to="regPasswordConfirm" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Company</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regCompany"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.company"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regCompany" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6">
            <v-row no-gutters>
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Country</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regCountry"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <AutocompleteField
                  v-model="formData.country"
                  class="login-form-input"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  :rules="rules"
                  checkBg
                >
                  <template #message="{ key, message }">
                    <portal to="regCountry" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </AutocompleteField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Address</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regAddress"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.address"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regAddress" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">City</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regCity"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.city"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regCity" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">ZIP</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regZIP"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.zip"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regZIP" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pb-4 pb-sm-10"
                :class="{ 'align-end': checkSm }"
                cols="12"
                sm="4"
              >
                <span class="font-normal loginColor--text">Phone</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="regPhone"
                ></portal-target>
              </v-col>
            
              <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
                <FormField
                  v-model="formData.phone"
                  class="login-form-input"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="regPhone" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col class="pl-12 d-flex justify-end align-center" cols="12">
                <div class="font-xs textLink--text pr-10">
                  <TextLink @click="goToLogin">Login</TextLink>
                </div>
              
                <ActionBtnForDarkBg
                  type="submit"
                  @click.prevent="submitForm"
                  :loading="loading"
                >
                  Register
                </ActionBtnForDarkBg>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    
    <v-card
      v-else
      flat
      class="inviteCardBg invite-connect"
      :class="isXs ? 'pa-15 pa-sm-20' : 'pa-8'"
      :max-width="isMobile ? '550' : '90%'"
    >
      <h2 class="font-logo-xs inviteTitle--text py-10">{{ infoMessage }}</h2>
    
      <div class="d-flex justify-end">
        <ActionOutlinedButton
          :to="{ name: 'AuthLogin' }"
          textColor="inviteFieldBorder--text"
          text
        >Log in</ActionOutlinedButton>
      </div>
    </v-card>
  </div>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import TextLink from '@/components/links/TextLink'

import { mapState } from 'vuex'

import { getRegister } from '@/api/auth-routes'

export default {
  name: 'AuthRegister',
  inheritAttrs: false,
  components: { FormField, AutocompleteField, ActionBtnForDarkBg, TextLink, ActionOutlinedButton },
  data: () => ({
    loading: false,
    registerSuccess: false,
    validForm: true,
    formData: {
      'first_name': null,
      'last_name': null,
      email: null,
      password: null,
      'password_confirmation': null,
      company: null,
      address: null,
      city: null,
      zip: null,
      country: null,
      phone: null,
    },
    rules: [],
    emailRules: [],
    passwordConfirmRules: [],
    infoMessage: null,
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  methods: {
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await getRegister(this.formData)

        if (success) {
          this.infoMessage = message
          this.registerSuccess = true
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.rules = [
        v => !!v || 'This field is required'
      ]
      this.passwordConfirmRules = [
        v => !!v || 'This field is required',
        v => v === this.formData.password || 'Passwords must match'
      ]
    },
    goToLogin() {
      this.$router.push({ name: 'AuthLogin' })
    },
    goToReset() {
      this.$router.push({ name: 'AuthReset' })
    },
  },
}
</script>
