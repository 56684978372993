<template>
  <v-hover #default="{ hover }">
    <a v-bind="$attrs" v-on="$listeners" :ripple="false">
      <span
        :class="[
          hover ? textColorHover : textColor,
          classes
        ]"
      >
        <slot></slot>
      </span>
    </a>
  </v-hover>
</template>

<script>
export default {
  name: 'TextLink',
  inheritAttrs: false,
  props: {
    classes: {
      type: String,
      default: 'font-xs'
    },
    textColor: {
      type: String,
      default: 'loginLinkColor--text'
    },
    textColorHover: {
      type: String,
      default: 'loginLinkColorHover--text'
    },
  }
}
</script>